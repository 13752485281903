const initialStore = {
  apiCallCount: 0,
  apiCallsStarted: false,
};

const isApiCallRunning = (actionType) => {
  if(actionType.indexOf('_REQUEST') > 0){
    return true;
  } else if(actionType.indexOf('_SUCCESS') > 0 || actionType.indexOf('_FAILED') > 0){
    return false;
  } else return 'N/A'
};

export default (state = initialStore, action) => {
  const apiCallStatus = isApiCallRunning(action.type);
  if(typeof apiCallStatus === "boolean"){
    const apiCallCount = apiCallStatus ? state.apiCallCount + 1 : Math.max(0, state.apiCallCount - 1);
    const apiCallsStarted = apiCallCount > 0;
    return {
      ...state,
      apiCallCount,
      apiCallsStarted,
    };
  }
  return {
    ...state
  };

}

